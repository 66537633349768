import React from "react";
import TypingAnimation from "../TypingAnimation";
import SkeletonLoader from "../SkeletonLoader";
import { FaMicrophone } from "react-icons/fa";
const TranscriptionsPreview = ({ transcription }) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="size-full p-8 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-mono font-bold">
            <FaMicrophone className="inline-block mr-1 animate-pulse" />
            Transcription
          </h1>
        </div>
        {transcription ? (
          <div>
            <div
              style={{ maxHeight: "40rem" }}
              className="overflow-y-auto mb-5 p-4 w-full mx-auto resize"
            >
              <TypingAnimation text={transcription} />
            </div>
          </div>
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  );
};
export default TranscriptionsPreview;
