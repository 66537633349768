import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../assets/swaaslogo.png";
import { axiosPost } from "../utils/axiosUtils";
import { API_ENDPOINTS } from "../constants/apiEndpoint";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [invalidCredsError, setInvalidCredsError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.setItem("isLoggedIn", false);
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    let isValid = true;
    if (!email.trim()) {
      setEmailError("Email is required");
      isValid = false;
    }
    if (!password.trim()) {
      setPasswordError("Password is required");
      isValid = false;
    }
    if (isValid) {
      try {
        const body = {
          username: email,
          password,
        };
        const response = await axiosPost(API_ENDPOINTS.DEMO_LOGIN_API_PATH, body);
        const { success, data } = response.data;
        if (success && data) {
          sessionStorage.setItem("isLoggedIn", "true");
          navigate("/home");
        } else {
          setInvalidCredsError("Invalid credentials");
        }
      } catch (error) {
        setInvalidCredsError("Login Failed. Please try again");
      }
    }
  };
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <img src={LOGO} alt="Logo" className="h-16 mb-8" />
      <div className="max-w-sm w-full">
        <div>
          <h4 className="mt-6 text-center text-md font-bold text-gray-900">
            Sign in to experience the power of Gen AI
          </h4>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="mb-2">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`appearance-none rounded-none relative block
                 w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                  text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm ${
                    emailError && "border-red-500"
                  }`}
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <p className="mt-2 text-sm text-red-500">{emailError}</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm ${
                  passwordError && "border-red-500"
                }`}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <p className="mt-2 text-sm text-red-500">{passwordError}</p>
              )}
            </div>
          </div>
          <div>
            {invalidCredsError && (
              <p className="mt-2 text-sm text-red-500">{invalidCredsError}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
