import React, { useEffect, useState } from "react";
import "../output.css";
import KeywordAnalyzerPreview from "./preview/KeywordAnalyzerPreview.jsx";
import DeepAnalysisPreview from "./preview/DeepAnalysisPreview.jsx";
import ConnectionsPreview from "./preview/ConnectionsPreview.jsx";
import TranscriptionsPreview from "./preview/TranscriptionsPreview.jsx";
import InsightsPreview from "./preview/InsightsPreview.jsx";
import SummaryPreview from "./preview/SummaryPreview.jsx";
function AIResultsPreview({ results, type }) {
  const [currentResults, setCurrentResults] = useState(null);
  const formatData = (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  };
  useEffect(() => {
    const transcriptionSummary = formatData(results?.summary);
    const transcriptionInsights = formatData(results?.insights);
    const data = {
      filePreview: results?.filePreviewURL,
      transcription: results?.transcription,
      transcriptionSummary,
      transcriptionInsights,
      keywordsRelationship: results?.connectionInfo,
      conversationFlowAnalysis: results?.deepAnalysis,
      selectedBrand: results?.brandName,
      selectedBrandKeywords: results?.brandKeywordAnalysis,
    };
    setCurrentResults({ ...data });
  }, [results]);
  return (
    <>
      <div className="h-screen">
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col ">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold">File Preview</h1>
              {currentResults?.filePreview && (
                <div className="w-full h-3/4 flex mx-auto items-center my-auto">
                  <video
                    controls
                    className="size-full rounded-lg mx-auto"
                    src={currentResults?.filePreview}
                  ></video>
                </div>
              )}
            </div>
          </div>
          <TranscriptionsPreview
            transcription={currentResults?.transcription}
          />
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6 mb-40">
          <SummaryPreview
            transcriptionSummary={currentResults?.transcriptionSummary}
          />
          <InsightsPreview
            transcriptionInsights={currentResults?.transcriptionInsights}
          />
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          {/* <ConnectionsPreview
            keywordsRelationship={currentResults?.keywordsRelationship}
          /> */}
          {currentResults?.conversationFlowAnalysis?.length > 0 && (
            <DeepAnalysisPreview
              conversationFlowAnalysis={
                currentResults?.conversationFlowAnalysis
              }
            />
          )}
        </div>
        <KeywordAnalyzerPreview
          transcription={currentResults?.transcription}
          selectedBrand={currentResults?.selectedBrand}
          selectedBrandKeywords={currentResults?.selectedBrandKeywords}
        />
      </div>
    </>
  );
}
export default AIResultsPreview;
