import React from "react";
import { Routes, Route } from "react-router-dom";
import Analyser from "../pages/Analyser.jsx";
import Login from "../pages/Login.jsx";
import Home from "../pages/Home.jsx";
import GenericAnalyser from "../pages/GenericAnalyser.jsx";
import GenericComparitativeAnalyserPOC from "../pages/GenericComparitativeAnalyserPOC.jsx";
const RouteConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/gen-ai" element={<Analyser />} />
      <Route path="/gen-ai-pro" element={<GenericAnalyser />} />
      <Route path="/gen-ai-pro-compare-poc" element={<GenericComparitativeAnalyserPOC />} />
      <Route path="/home" element={<Home />} />
    </Routes>
  );
};

export default RouteConfig;
