import React from "react";
import SkeletonLoader from "../SkeletonLoader";
import { FaLink } from "react-icons/fa";
const KeywordAnalyzerPreview = ({
  transcription,
  selectedBrand,
  selectedBrandKeywords,
}) => {
  return (
    <>
      {transcription && selectedBrandKeywords?.length > 0 && (
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaLink className="inline-block mr-2 animate-pulse" />
                  Keywords Usage Analysis:
                </h1>

                <>
                  <label
                    htmlFor="brandName"
                    className="mr-2 mt-2 block flex-row"
                  >
                    Brand: {selectedBrand}
                  </label>
                  <div className="container mx-auto py-8">
                    <div className="overflow-auto max-h-full">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
                            >
                              Keyword
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium transcription-gray-500 uppercase tracking-wider font-mono"
                            >
                              Expected Mentions
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
                            >
                              Actual Mentions
                            </th>
                            <th
                              scope="col"
                              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
                            >
                              Percentage of Mentions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {selectedBrandKeywords.map((data, index) => (
                            <tr key={index}>
                              <td className="px-4 py-2 whitespace-nowrap font-mono">
                                {data.key_word}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap font-mono">
                                {data.expected_mentions}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap font-mono">
                                {data.actual_occurances}
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap font-mono">
                                {data.percentage_of_occurances} %
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
                {/* ) : (
              <SkeletonLoader />
            )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default KeywordAnalyzerPreview;
