import React, { useState, useEffect } from "react";
import "../output.css";
import BRANDS from "../dumps/brands.json";
import BRAND_KEYWORDS from "../dumps/brandKeywords.json";
import TypingAnimation from "../components/TypingAnimation.jsx";
import SkeletonLoader from "../components/SkeletonLoader.jsx";
import { FaChartLine } from "react-icons/fa";
import { MdCompare } from "react-icons/md";
import { FaExchangeAlt } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header.jsx";
import AI_RESULTS from "../dumps/results.json";
import TranscriptionsPreview from "../components/preview/TranscriptionsPreview.jsx";
import InsightsPreview from "../components/preview/InsightsPreview.jsx";
import SummaryPreview from "../components/preview/SummaryPreview.jsx";
function GenericComparitativeAnalyserPOC() {
  const [standardResults, setStandardResults] = useState(null);
  const [traineeResults, setTraineeResults] = useState(null);
  const [selectedAreaOfAnalysis, setselectedAreaOfAnalysis] = useState(null);
  const [selectedAreaOfAnalysisKeywords, setselectedAreaOfAnalysisKeywords] =
    useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn) {
      navigate("/login");
    }
    let area = state?.area;
    if (!area) {
      area = "INSURANCE";
    }
    setselectedAreaOfAnalysis(area);
  }, []);

  const handleAreaOfAnalysisChange = (area) => {
    if (area) setselectedAreaOfAnalysis(area);
    const areaData = BRAND_KEYWORDS.find(
      (ele) => ele.code.toLowerCase() === area.toLowerCase()
    );
    const areaKeywordsArr = areaData.keywords;
    console.log(areaKeywordsArr);
    setselectedAreaOfAnalysisKeywords([...areaKeywordsArr]);
    // const areaKeywords = areaKeywordsArr.map((ele) => ele.name).join(",");
  };
  useEffect(() => {
    const formatData = (data) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    };
    const prepareResults = (results) => {
      if (!results) return null;
      const transcriptionSummary = formatData(results?.summary);
      const transcriptionInsights = formatData(results?.insights);
      return {
        filePreview: results?.filePreviewURL,
        transcription: results?.transcription,
        transcriptionSummary,
        transcriptionInsights,
        keywordsRelationship: results?.connectionInfo,
        conversationFlowAnalysis: results?.deepAnalysis,
        selectedBrand: results?.brandName,
        selectedBrandKeywords: results?.brandKeywordAnalysis,
        rawComparision: results?.rawComparision,
        specificComparision: results?.specificComparision,
        areasOfImprovement: results?.areasOfImprovement,
      };
    };
    const standardRefResults = AI_RESULTS[selectedAreaOfAnalysis];
    const standardData = prepareResults(standardRefResults);
    setStandardResults({ ...standardData });

    if (selectedAreaOfAnalysis) {
      const traineeRefResults =
        AI_RESULTS[`${selectedAreaOfAnalysis}_TRAINEE_SAMPLE`];
      const traineeData = prepareResults(traineeRefResults);
      setTraineeResults({ ...traineeData });
    }
  }, [selectedAreaOfAnalysis]);
  return (
    <>
      <Header />
      <div className="h-screen">
        <div className="m-10">
          <div className="flex">
            <label htmlFor="areas" className="mr-2 mt-2 block flex-row">
              Choose Area:
            </label>
            <select
              id="areas"
              value={selectedAreaOfAnalysis}
              onChange={(e) => handleAreaOfAnalysisChange(e.target.value)}
              className="border border-gray-300 p-2 mb-2 font-mono"
              disabled
              title="More areas are unlocking soon..."
            >
              {BRANDS && BRANDS.length > 0 ? (
                <>
                  <option key={-1} value="">
                    Choose Area
                  </option>
                  {BRANDS.map((ele, index) => (
                    <option key={index} value={ele.code}>
                      {ele.label}
                    </option>
                  ))}
                </>
              ) : null}
            </select>
          </div>
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col ">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold mb-2">
              🎯 Benchmark Content
              </h1>
              {standardResults?.filePreview && (
                <>
                  <div className="w-full h-3/4 flex mx-auto items-center my-auto border-blue-500 shadow-lg">
                    <video
                      controls
                      className="size-full rounded-lg mx-auto"
                      src={standardResults?.filePreview}
                    ></video>
                  </div>
                </>
              )}
            </div>
          </div>
        
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold mb-2">
                Trainee's Content
              </h1>
              {traineeResults?.filePreview && (
                <>
                  <div className="w-full h-3/4 flex mx-auto items-center my-auto">
                    <video
                      controls
                      className="size-full rounded-lg mx-auto"
                      src={traineeResults?.filePreview}
                    ></video>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <TranscriptionsPreview
            transcription={standardResults?.transcription}
          />
          <TranscriptionsPreview
            transcription={traineeResults?.transcription}
          />
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <SummaryPreview
            transcriptionSummary={standardResults?.transcriptionSummary}
          />
          <SummaryPreview
            transcriptionSummary={traineeResults?.transcriptionSummary}
          />
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6 mb-80">
          <InsightsPreview
            transcriptionInsights={standardResults?.transcriptionInsights}
          />
          <InsightsPreview
            transcriptionInsights={traineeResults?.transcriptionInsights}
          />
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6"></div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <MdCompare className="inline-block mr-2 animate-pulse" />
                  Raw Comparision
                </h1>
                {traineeResults?.rawComparision ? (
                  <div
                    style={{ maxHeight: "45rem" }}
                    className="overflow-y-auto max-h-128 mb-10 p-4 w-full mx-auto"
                  >
                    <TypingAnimation text={traineeResults?.rawComparision} />
                  </div>
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto h-3/4 flex gap-6 p-6">
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaExchangeAlt className="inline-block mr-2 animate-pulse" />
                  Specific Prompt Based Comparision
                </h1>
                {traineeResults?.specificComparision ? (
                  <div
                    style={{ maxHeight: "38rem" }}
                    className="overflow-y-auto max-h-128 mb-10 p-4 w-full mx-auto"
                  >
                    <TypingAnimation
                      text={traineeResults?.specificComparision}
                    />
                  </div>
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto h-3/4 flex gap-6 p-6">
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaChartLine className="inline-block mr-2 animate-pulse" />
                  Areas of Improvement
                </h1>
                {traineeResults?.areasOfImprovement ? (
                  <div
                    style={{ maxHeight: "45rem" }}
                    className="overflow-y-auto max-h-128 mb-10 p-2 w-full mx-auto"
                  >
                    <TypingAnimation
                      text={traineeResults?.areasOfImprovement}
                    />
                  </div>
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GenericComparitativeAnalyserPOC;
