import React, { useEffect, useState } from "react";

function KeywordAnalyzer({ transcription, brandKeywords }) {
  const [keywordCounts, setkeywordCounts] = useState([]);

  const analyzeKeywords = () => {
    let keywordsAnalytics = [];

    brandKeywords.forEach(({ name, expected_mentions }) => {
      // Count occurrences of the word or phrase in the transcription
      const regex = new RegExp("\\b" + name.toLowerCase() + "\\b", "g");
      const actualCount = (transcription.toLowerCase().match(regex) || [])
        .length;

      const percentage = ((actualCount / expected_mentions) * 100).toFixed(2);

      keywordsAnalytics.push({
        key_word: name,
        expected_mentions,
        actual_occurances: actualCount,
        percentage_of_occurances: isNaN(percentage)
          ? "0"
          : Math.floor(percentage),
      });
    });

    console.log(keywordsAnalytics);
    console.log(JSON.stringify(keywordsAnalytics));
    keywordsAnalytics = keywordsAnalytics.sort(
      (a, b) => b.percentage_of_occurances - a.percentage_of_occurances
    );
    setkeywordCounts(keywordsAnalytics);
  };

  useEffect(() => {
    if (brandKeywords) analyzeKeywords();
  }, [transcription, brandKeywords]);

  return (
    <div className="container mx-auto py-8">
      {/* <button
        className="mb-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded font-mono"
        onClick={analyzeKeywords}
      >
        Analyze Keywords
      </button> */}
      <div className="overflow-auto max-h-full">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
              >
                Keyword
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium transcription-gray-500 uppercase tracking-wider font-mono"
              >
                Expected Mentions
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
              >
                Actual Mentions
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider font-mono"
              >
                Percentage of Mentions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {keywordCounts.map((keywordCount, index) => (
              <tr key={index}>
                <td className="px-4 py-2 whitespace-nowrap font-mono">
                  {keywordCount.key_word}
                </td>
                <td className="px-4 py-2 whitespace-nowrap font-mono">
                  {keywordCount.expected_mentions}
                </td>
                <td className="px-4 py-2 whitespace-nowrap font-mono">
                  {keywordCount.actual_occurances}
                </td>
                <td className="px-4 py-2 whitespace-nowrap font-mono">
                  {keywordCount.percentage_of_occurances} %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default KeywordAnalyzer;
