import React from "react";
import TypingAnimation from "../TypingAnimation";
import SkeletonLoader from "../SkeletonLoader";
import { FaLightbulb } from "react-icons/fa";
const InsightsPreview = ({ transcriptionInsights }) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="w-full p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-mono font-bold mb-4">
          <FaLightbulb className="inline-block mr-1 animate-pulse" /> Insights
        </h1>
        {transcriptionInsights ? (
          transcriptionInsights?.map((ele, index) => (
            <div
              key={index}
              className="overflow-y-auto max-h-128 mb-2 border shadow-sm rounded-md p-4 w-full"
            >
              <h2 className="text-lg font-semibold">
                {ele?.team_name && <label>{`${ele?.team_name}-`}</label>}
                {ele.category_name}:
              </h2>
              <p>Rating: {ele.star_rating}</p>
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <div className="relative inline-block">
                  <span className="text-md font-mono">
                    {ele.reason_for_provided_star_rating}
                  </span>
                </div>
              </pre>
            </div>
          ))
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  );
};
export default InsightsPreview;
