import axios from "axios";
const APIBaseURL =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_VERSION;

const setHeaders = (token) => {
  const headers = {
    Authorization: "Bearer " + token,
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  };
  return headers;
};

export const axiosInstance = (token) => {
  axios.defaults.baseURL = APIBaseURL
  if (token) {
    axios.defaults.headers.common = setHeaders(token);
  }
  return axios;
};

export const axiosGet = async (endpoint, params = {}, token = "") => {
  const response = await axiosInstance(token).get(endpoint, {
    params,
  });
  return response;
};

export const axiosPost = async (endpoint, body = {}, token = "") => {
  const response = await axiosInstance(token).post(endpoint, body);
  return response;
};
