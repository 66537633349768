import React from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../assets/swaaslogo.png";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.setItem("isLoggedIn", "false");
    navigate("/");
  };
  const goToHomePage = () => {
    navigate("/home");
  };
  return (
    <header className="bg-gray-200 text-black p-4 flex justify-between items-center">
      <div className="flex items-center">
        <img
          src={LOGO}
          alt="Logo"
          className="h-10 w-auto mr-2 cursor-pointer"
          onClick={goToHomePage}
        />
        <span className="text-xl font-bold font-mono h-8">Generative AI</span>
      </div>
      <button
        onClick={handleLogout}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded font-mono"
      >
        Logout
      </button>
    </header>
  );
};

export default Header;
