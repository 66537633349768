import React, { useState, useEffect } from "react";
const TypingAnimation = ({ text }) => {
  const [currentText, setCurrentText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const delay = 10;
    const initInterval = () => {
      const typingInterval = setInterval(() => {
        if (currentIndex < text.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(typingInterval);
        }
      }, delay);
      return typingInterval;
    };

    const typingInterval = initInterval();
    return () => clearInterval(typingInterval);
  }, [currentText, currentIndex]);

  useEffect(() => {
    if (currentText != text) {
      setDisplayText("");
      setCurrentIndex(0);
      setCurrentText(text);
    }
  }, [text]);
  return (
    <pre
      style={{
        whiteSpace: "pre-wrap",
      }}
    >
      <div className="relative inline-block">
        <span className="text-md font-mono">{displayText}</span>
      </div>
    </pre>
  );
};
export default TypingAnimation;
