import React, { useState, useEffect } from "react";
import "../output.css";
import BRANDS from "../dumps/brands.json";
import BRAND_KEYWORDS from "../dumps/brandKeywords.json";
import TypingAnimation from "../components/TypingAnimation.jsx";
import axios from "axios";
import SkeletonLoader from "../components/SkeletonLoader.jsx";
import {
  FaDownload,
  FaCheckCircle,
  FaMicrophone,
  FaListUl,
  FaLightbulb,
  FaBrain,
  FaLink,
} from "react-icons/fa";
import KeywordAnalyzer from "../components/KeywordAnalyzer.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header.jsx";
import { axiosGet, axiosPost } from "../utils/axiosUtils.js";
import { formatData } from "../utils/formatData.js";
import { API_ENDPOINTS } from "../constants/apiEndpoint.js";
function Analyser() {
  const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFileType, setSelectedFileType] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState("");
  const [transcription, setTranscription] = useState("");
  const [transcriptionFileURL, setTranscriptionFileURL] = useState(null);
  const [transcriptionSummary, setTranscriptionSummary] = useState("");
  const [transcriptionInsights, setTranscriptionInsights] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [keywordsRelationship, setKeywordsRelationship] = useState("");
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedTermAdditionalInfo, setSelectedTermAdditionalInfo] =
    useState("");
  const [conversationFlowAnalysis, setConversationFlowAnalysis] = useState("");
  const [selectedBrand, setselectedBrand] = useState(null);
  const [selectedBrandKeywords, setselectedBrandKeywords] = useState([]);
  const [uploadInprogress, setUploadInProgress] = useState(false);
  const [summaryGenerationInprogress, setSummaryGenerationInProgress] =
    useState(false);
  const [insightsGenerationInprogress, setInsightsGenerationInProgress] =
    useState(false);
  const [analysisGenerationInprogress, setAnalysisGenerationInProgress] =
    useState(false);
  const [transcriptionInprogress, setTranscriptionInProgress] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);
  const DOMAIN = "PHARMA";
  useEffect(() => {
    const fetchAdditionalInfo = async (keyword) => {
      if (keyword !== null) {
        try {
          const ADDITIONAL_INFO_API_URL =
            API_ENDPOINTS.ADDITIONAL_INFO_API_PATH +
            "?keyword=" +
            keyword +
            "&domain=" +
            DOMAIN;
          const response = await axiosGet(ADDITIONAL_INFO_API_URL);
          const { data } = response.data;
          let result = formatData(data);
          result = result.trim();
          setSelectedTermAdditionalInfo(result);
        } catch (error) {
          setSelectedTermAdditionalInfo("");
        }
      }
    };
    if (openIndex)
      fetchAdditionalInfo(
        transcriptionSummary[0]?.diseases_and_conditions[openIndex]
      );
    return () => {
      setSelectedTermAdditionalInfo("");
    };
  }, [openIndex]);
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Sanitize file name
      const sanitizedFileName = file.name
        .replace(/[^a-zA-Z0-9.]/g, "_")
        .replace(/\s+/g, "_");
      const sanitizedFile = new File([file], sanitizedFileName, {
        type: file.type,
      });
      setSelectedFile(sanitizedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFilePreview("");
    }
  };
  const uploadFileToS3 = async () => {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }
    setUploadInProgress(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    setVideoFileName(selectedFile.name);
    try {
      const UPLOAD_API_URL =
        process.env.REACT_APP_API_BASE_URL +
        process.env.REACT_APP_API_VERSION +
        API_ENDPOINTS.UPLOAD_FILE_API_PATH;
      const response = await axios.post(UPLOAD_API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadedFileUrl(response.data.data);
      setUploadInProgress(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file");
      setUploadInProgress(false);
    }
  };
  const transcribeFileV1 = async () => {
    let resultOfTranscription =
      "Good morning, ma'am. Good morning myself. Binder Singh from Amin Pharma. Amin Pharma is committed to provide services in in humanity with quality ma'am. It is also in in national healthcare excellence. About 2016, ma'am. It is also in in international business. About 2018, ma'am. Amin farmer is most emerging Pharma company. 2018, ma'am, ma'am my first brand Neuro CC plus Sophal capsule. Ma'am introducing K +27 combination for complete wellness of bone ma'am. Then quality composition is a methyl Camin 1500 mcg, ma'am with vitamin K +27, 50 mg, vitamin D 3, 1000 iu Ma'am calcium sat mi 500 mg ma'am with calcitriol 0.25 mg ma'am magnesium hydroxide 50 mg zinc sulfate 22.5 mg Ma'am. Calci oil is reduce the verte uh reduce the risk of vertebrae and post menopausal woman. Ma'am. Calcium carbonate is a play critical role in body ma'am essential growth sector. Methyl Kalam is a mainly neuroprotective agent. Ma'am. The zinc is nucleic acid and metabolism, protein fats, carbohydrates, magnesium oxide, increase the bone mineral density in body ma'am and vitamin K two regulate the proper bo bone functions and calcium absorptions and increase the bone mineral density, reduce the fracture rate, ma'am and prevent the calcium from hardening of the blood vessel. Ma'am today. My humble request, ma'am, please and please prescribe in neuro back CC plus Sophal capsule, ma'am in post menopausal osteoporosis, ma'am and uh S uh senile osteoporosis, renal osteodystrophy, ma'am and osteomalacia steroid induced osteoporosis, ma'am, pregnancy and lactation. Thank you, ma'am. Ok. Is it available to the nearest store? Yes, ma'am. Sure. Please prescribe her. Ok. Sure.";
    setTranscription(resultOfTranscription);
  };
  const transcribeFile = async () => {
    if (!uploadedFileUrl) {
      alert("Please upload a file first");
      return;
    }
    console.log("Starting transcription...");
    setTranscriptionInProgress(true);
    try {
      const body = {
        fileURL: uploadedFileUrl,
        fileName: videoFileName,
      };
      const response = await axiosPost(API_ENDPOINTS.TRANSCRIBE_API_PATH, body);
      const { data } = response.data;
      setTranscription(data.transcript);
      console.log("Finished transcription");
      setTranscriptionFileURL(data.transcriptURL);
      setTranscriptionInProgress(false);
    } catch (error) {
      console.error("Error transcribing file:", error);
      alert("Failed to transcribe file");
      setTranscriptionInProgress(false);
    }
  };
  const generateSummary = async () => {
    setSummaryGenerationInProgress(true);
    try {
      const body = {
        transcriptURL: transcriptionFileURL,
        domain: DOMAIN,
      };
      const response = await axiosPost(API_ENDPOINTS.SUMMARIZE_API_PATH, body);
      const { data } = response.data;
      const summary = formatData(data);
      setTranscriptionSummary(summary);
      setSummaryGenerationInProgress(false);
      getRelationAmongKeywords(summary);
    } catch (error) {
      setTranscriptionSummary("Error occured.. Please try again");
      setSummaryGenerationInProgress(false);
    }
  };
  const generateInsights = async () => {
    setInsightsGenerationInProgress(true);
    try {
      const body = {
        transcriptURL: transcriptionFileURL,
        domain: DOMAIN,
      };
      const response = await axiosPost(API_ENDPOINTS.INSIGHTS_API_PATH, body);
      const { data } = response.data;
      const insights = formatData(data);
      setTranscriptionInsights(insights);
      setInsightsGenerationInProgress(false);
    } catch (error) {
      setTranscriptionInsights("Error occured.. Please try again");
      setInsightsGenerationInProgress(false);
    }
  };
  const getConversationFlowAnalysis = async (transcription) => {
    if (transcription && transcription.length > 0) {
      setAnalysisGenerationInProgress(true);
      try {
        const body = {
          transcriptURL: transcriptionFileURL,
          domain: DOMAIN,
        };
        const response = await axiosPost(API_ENDPOINTS.DEEP_ANALYSIS_API_PATH, body);
        const { data } = response.data;
        setConversationFlowAnalysis(data);
        setAnalysisGenerationInProgress(false);
      } catch (error) {
        setConversationFlowAnalysis("Error occured.. Please try again");
        setAnalysisGenerationInProgress(false);
      }
    }
  };
  const getRelationAmongKeywords = async (summary) => {
    if (summary && summary.length > 0) {
      const keywords = summary[0]?.diseases_and_conditions;
      if (keywords && keywords?.length > 0) {
        try {
          const body = {
            keywords: keywords,
            domain: DOMAIN,
          };
          const response = await axiosPost(API_ENDPOINTS.CONNECTION_INFO_API_PATH, body);
          const { data } = response.data;
          setKeywordsRelationship(data);
        } catch (error) {
          setKeywordsRelationship("Error occured.. Please try again");
        }
      }
    }
  };
  const handleBrandChange = (brand) => {
    if (brand) setselectedBrand(brand);
    const brandData = BRAND_KEYWORDS.find(
      (ele) => ele.code.toLowerCase() === brand.toLowerCase()
    );
    const brandKeywordsArr = brandData.keywords;
    console.log(brandKeywordsArr);
    setselectedBrandKeywords([...brandKeywordsArr]);
  };
  return (
    <>
      <Header />
      <div className="h-screen">
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col ">
            {/* Left half */}
            <div className="size-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold mb-2">File Upload</h1>
              <div className="mb-4">
                <div className="flex">
                  <label htmlFor="brands" className="mr-2 mt-2 block flex-row">
                    Choose Brand:
                  </label>
                  <select
                    id="brands"
                    onChange={(e) => handleBrandChange(e.target.value)}
                    className="border border-gray-300 p-2 mb-2 font-mono"
                  >
                    {BRANDS && BRANDS.length > 0 ? (
                      <>
                        <option key={-1} value="">
                          Select a brand
                        </option>
                        {BRANDS.map((ele, index) => (
                          <option key={index} value={ele.code}>
                            {ele.label}
                          </option>
                        ))}
                      </>
                    ) : null}
                  </select>
                </div>
                <div>
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    // accept="video/*"
                    className="border border-gray-300 p-2 mb-2 font-mono"
                  />
                  {selectedFile && (
                    <>
                      {!uploadInprogress ? (
                        <button
                          onClick={uploadFileToS3}
                          className="bg-blue-500 text-white ml-2 mr-2 px-4 py-2 rounded-md"
                        >
                          Upload
                        </button>
                      ) : (
                        <button
                          disabled=""
                          type="button"
                          className="ml-2 mr-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                        >
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            ></path>
                          </svg>
                          Uploading...
                        </button>
                      )}
                    </>
                  )}
                  {uploadedFileUrl && (
                    <React.Fragment>
                      <p className="inline-block align-middle mr-2">
                        File uploaded successfully!
                      </p>
                      <span className="inline-block align-middle">
                        <FaCheckCircle style={{ color: "green" }} />
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {filePreview && (
                <div className="mb-4">
                  <p className="font-semibold mb-2">File Preview:</p>
                  <video controls className="w-full" src={filePreview}></video>
                </div>
              )}
              <div className="flex">
                {uploadedFileUrl && (
                  <>
                    {!transcriptionInprogress ? (
                      <button
                        onClick={transcribeFile}
                        className="bg-blue-500 text-white flex items-center ml-2 px-4 py-2 rounded-md"
                      >
                        <FaMicrophone className="mr-2" />
                        <span>Transcribe</span>
                      </button>
                    ) : (
                      <button
                        disabled=""
                        type="button"
                        className="ml-2 mr-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          ></path>
                        </svg>
                        Transcribing...
                      </button>
                    )}
                  </>
                )}
                {transcription && (
                  <>
                    {!summaryGenerationInprogress ? (
                      <button
                        onClick={generateSummary}
                        className="bg-blue-500 text-white flex items-center ml-2 px-4 py-2 rounded-md"
                      >
                        <FaListUl className="mr-2" />
                        <span>Summarize</span>
                      </button>
                    ) : (
                      <button
                        disabled=""
                        type="button"
                        className="ml-2 mr-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          ></path>
                        </svg>
                        Summarizing...
                      </button>
                    )}
                  </>
                )}
                {transcription && (
                  <>
                    {!insightsGenerationInprogress ? (
                      <button
                        onClick={generateInsights}
                        className="bg-blue-500 text-white flex items-center ml-2 px-4 py-2 rounded-md"
                      >
                        <FaLightbulb className="mr-2" />
                        <span>Generate Insights</span>
                      </button>
                    ) : (
                      <button
                        disabled=""
                        type="button"
                        className="ml-2 mr-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          ></path>
                        </svg>
                        Generating...
                      </button>
                    )}
                  </>
                )}
                {transcription && (
                  <>
                    {!analysisGenerationInprogress ? (
                      <button
                        onClick={() => {
                          getConversationFlowAnalysis(transcription);
                        }}
                        className="bg-blue-500 text-white flex items-center ml-2 px-4 py-2 rounded-md"
                      >
                        <FaBrain className="mr-2" />
                        <span>Deep Analysis</span>
                      </button>
                    ) : (
                      <button
                        disabled=""
                        type="button"
                        className="ml-2 mr-2 px-4 py-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                      >
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#1C64F2"
                          ></path>
                        </svg>
                        Analysing...
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Right half */}
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-mono font-bold">
                  <FaMicrophone className="inline-block mr-1 animate-pulse" />{" "}
                  Transcription
                </h1>
                {transcription && (
                  <a
                    href={transcriptionFileURL}
                    className="flex items-center text-blue-500 underline"
                  >
                    <FaDownload className="mr-2" /> Download
                  </a>
                )}
              </div>
              {transcription ? (
                <div>
                  <div
                    style={{ maxHeight: "40rem" }}
                    className="overflow-y-auto mb-5 p-4 w-full mx-auto"
                  >
                    <TypingAnimation text={transcription} />
                  </div>
                </div>
              ) : (
                <SkeletonLoader />
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6 mb-80">
          <div className="flex-1 flex flex-col">
            {/* Left half */}
            <div className="w-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaListUl className="inline-block mr-1 animate-pulse" />{" "}
                  Summary
                </h1>
                {transcriptionSummary ? (
                  Array.isArray(transcriptionSummary) === true ? (
                    <div className="space-y-8">
                      {transcriptionSummary.map((item, index) => (
                        <div
                          key={index}
                          // className="border p-4 rounded-md shadow-md"
                          className="p-4"
                        >
                          <TypingAnimation
                            text={item?.summary || "Summary Not available"}
                          />
                          <h1 className="text-xl font-bold mt-5">Keywords:</h1>
                          <div className="mt-5">
                            <div>
                              {item?.drug_names?.brand_names &&
                                item?.drug_names?.brand_names.length > 0 && (
                                  <>
                                    <h3 className="text-lg font-semibold mb-2">
                                      Brand Names:
                                    </h3>
                                    <ol className="list-decimal pl-6 mb-4">
                                      {item?.drug_names?.brand_names?.map(
                                        (brand, i) => (
                                          <li key={i}>{brand}</li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                )}
                              {item?.drug_names?.generic_names &&
                                item?.drug_names?.generic_names?.length > 0 && (
                                  <>
                                    <h3 className="text-lg font-semibold mb-2">
                                      Generic Names:
                                    </h3>
                                    <ol className="list-decimal pl-6 mb-4">
                                      {item?.drug_names?.generic_names?.map(
                                        (generic, i) => (
                                          <li key={i}>
                                            {/* {item.drug_names.brand_names.length +
                                          i +
                                          1}
                                        . */}
                                            {generic}
                                          </li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                )}
                            </div>
                          </div>
                          <div></div>
                          <div>
                            <h2 className="text-xl font-semibold mb-2">
                              Diseases and Conditions:
                            </h2>
                            <ol className="list-disc pl-6 mb-4">
                              {item?.diseases_and_conditions?.map(
                                (condition, i) => (
                                  <li key={i}>
                                    {/* {i + 1}. {condition} */}
                                    <button
                                      onClick={() => toggleAccordion(i)}
                                      className="flex justify-between w-full bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-md mb-2"
                                    >
                                      <span>
                                        {/* {i + 1}. */}
                                        {condition}
                                      </span>
                                      <span>{openIndex === i ? "-" : "+"}</span>
                                    </button>
                                    {openIndex === i && (
                                      <div className="p-4 bg-gray-100 rounded-md">
                                        {selectedTermAdditionalInfo ? (
                                          // <pre
                                          //   style={{
                                          //     maxHeight: "34rem",
                                          //     maxWidth: "600px",
                                          //     whiteSpace: "pre-wrap",
                                          //   }}
                                          // >
                                          <div
                                            style={{ maxHeight: "10rem" }}
                                            className="overflow-y-auto max-h-128 w-full mx-auto"
                                          >
                                            <TypingAnimation
                                              text={selectedTermAdditionalInfo}
                                            />
                                          </div>
                                        ) : (
                                          // </pre>
                                          <p className="animate-pulse text-gray-400">
                                            Fetching additional information
                                            about {condition}.....
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </li>
                                )
                              )}
                            </ol>
                          </div>
                          <div className="mt-5">
                            <div>
                              {item?.manufacturers &&
                                item?.manufacturers.length > 0 && (
                                  <>
                                    <h3 className="text-lg font-semibold mb-2">
                                      Manufacturers:
                                    </h3>
                                    <ol className="list-decimal pl-6 mb-4">
                                      {item?.manufacturers?.map(
                                        (manufacturer, i) => (
                                          <li key={i}>{manufacturer}</li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{ maxHeight: "40rem" }}
                        className="overflow-y-auto mb-5 p-4 w-full mx-auto"
                      >
                        <TypingAnimation text={transcriptionSummary} />
                      </div>
                    </div>
                  )
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
          {/* Right half */}
          <div className="flex-1 flex flex-col">
            <div className="w-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold mb-4">
                <FaLightbulb className="inline-block mr-1 animate-pulse" />{" "}
                Insights
              </h1>
              {transcriptionInsights && transcriptionInsights?.length > 0 ? (
                Array.isArray(transcriptionInsights) &&
                transcriptionInsights?.map((ele, index) => (
                  <div
                    key={index}
                    className="overflow-y-auto max-h-128 mb-2 border shadow-sm rounded-md p-4 w-full"
                  >
                    <h2 className="text-lg font-semibold">
                      {ele.category_name}:
                    </h2>
                    <p>Rating: {ele.star_rating}</p>
                    <TypingAnimation
                      text={ele.reason_for_provided_star_rating}
                    />
                  </div>
                ))
              ) : (
                <SkeletonLoader />
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6 mt-10">
          <div className="flex-1 flex flex-col">
            {/* Left half */}
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaLink className="inline-block mr-2 animate-pulse" />
                  Exploring Connections
                </h1>
                {keywordsRelationship ? (
                  <div
                    style={{ maxHeight: "38rem" }}
                    className="overflow-y-auto max-h-128 mb-10 p-4 w-full mx-auto"
                  >
                    <TypingAnimation text={keywordsRelationship} />
                  </div>
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
          {/* Right half */}
          <div className="flex-1 flex flex-col">
            <div className="size-full p-8 rounded-lg shadow-lg">
              <h1 className="text-2xl font-mono font-bold mb-4">
                <FaBrain className="inline-block mr-1 animate-pulse" /> Deep
                Analysis and Recommendations:
              </h1>
              {conversationFlowAnalysis ? (
                <div
                  style={{ maxHeight: "38rem" }}
                  className="overflow-y-auto max-h-128 mb-20 p-4 w-full mx-auto"
                >
                  <TypingAnimation text={conversationFlowAnalysis} />
                </div>
              ) : (
                <SkeletonLoader />
              )}
            </div>
          </div>
        </div>
        <div className="container mx-auto h-screen flex gap-6 p-6">
          <div className="flex-1 flex flex-col">
            {/* Left half */}
            <div className="size-full p-8 rounded-lg shadow-lg">
              <div className="mb-4">
                <h1 className="text-2xl font-mono font-bold mb-4">
                  <FaLink className="inline-block mr-2 animate-pulse" />
                  Keywords Usage Analysis:
                </h1>
                {transcription && selectedBrandKeywords ? (
                  <>
                    <label
                      htmlFor="brandName"
                      className="mr-2 mt-2 block flex-row"
                    >
                      Brand: {selectedBrand}
                    </label>
                    <KeywordAnalyzer
                      transcription={transcription}
                      brandKeywords={selectedBrandKeywords}
                    />
                  </>
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Analyser;
