import React, { useState, useEffect } from "react";
import PHARMA_IMG from "../assets/rep-and-doctor.jpg";
// import FINANCE_IMG from "../assets/finance.jpg";
import AUTOMOBILE_IMG from "../assets/automobile.gif";
import NBA_IMG from "../assets/nba-logo.gif";
import INSURANCE_IMG from "../assets/insurance.gif";
import Header from "../components/Header.jsx";
import { FaExchangeAlt, FaLightbulb } from "react-icons/fa";
import AIResultsPreview from "../components/AIResultsPreview.jsx";
import AI_RESULTS from "../dumps/results.json";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const [type, setType] = useState("");
  const [aiResults, setAIResults] = useState(null);
  const navigate = useNavigate();
  const configureAreaOfAnalysis = (type) => {
    setType(type);
    const data = AI_RESULTS[type];
    if (data) setAIResults({ ...data });
  };
  const goToGenAI = () => {
    navigate("/gen-ai");
  };
  const goToGenAIPro = () => {
    navigate("/gen-ai-pro");
  };
  const goToGenAIProComparer = (area) => {
    navigate("/gen-ai-pro-compare-poc", { state: { area } });
  };
  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Header />
      <div className="flex justify-end mt-5 mr-5">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
          onClick={goToGenAI}
        >
          Try Pharma Analysis
        </button>
        <button
          className="bg-white-500 text-black ml-5 px-4 py-2 rounded-md border-blue-500 border-2"
          onClick={goToGenAIPro}
        >
          Try Generic Analysis
        </button>
      </div>
      <div className="container mx-auto flex gap-6 p-6 mt-5">
        <AreaOfAnalysisCard
          areaOfAnalysisCode={"PHARMA"}
          title={"Pharmaceutical Marketing"}
          imgSrc={PHARMA_IMG}
          marginTop={0}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
        />
        <AreaOfAnalysisCard
          areaOfAnalysisCode={"AUTOMOBILE"}
          title={"Automobile Review Analysis"}
          imgSrc={AUTOMOBILE_IMG}
          marginTop={10}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
        />
        <AreaOfAnalysisCard
          areaOfAnalysisCode={"NBA"}
          title={"NBA Post Game Analysis"}
          imgSrc={NBA_IMG}
          marginTop={0}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
        />
        {/*  <AreaOfAnalysisCard
          areaOfAnalysisCode={"finance"}
          title={"Financial Performance"}
          imgSrc={FINANCE_IMG}
          marginTop={10}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
        />
     <AreaOfAnalysisCard
          areaOfAnalysisCode={"news"}
          title={"News"}
          imgSrc={PHARMA_IMG}
          marginTop={0}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
        /> */}
      </div>
      <div className="w-1/3 container mx-auto flex gap-6 p-6">
        <AreaOfAnalysisCard
          areaOfAnalysisCode={"INSURANCE"}
          title={"Insurance Agent Training"}
          imgSrc={INSURANCE_IMG}
          marginTop={10}
          configureAreaOfAnalysis={configureAreaOfAnalysis}
          goToGenAIProComparer={() => goToGenAIProComparer("INSURANCE")}
        />
      </div>
      {aiResults && <AIResultsPreview results={aiResults} type={type} />}
    </>
  );
};
export default Home;
const AreaOfAnalysisCard = ({
  areaOfAnalysisCode,
  title,
  imgSrc,
  marginTop,
  configureAreaOfAnalysis,
  goToGenAIProComparer,
}) => {
  const handleGenerateAnalysis = (type) => {
    configureAreaOfAnalysis(type);
  };
  const navigateToGenAIProComparer = () => {
    goToGenAIProComparer();
  };
  return (
    <div className="flex-1 flex flex-col">
      <div className="size-full p-8 border-2 rounded-lg shadow-lg hover:border-blue-300">
        <h1 className="text-2xl font-mono font-bold text-center mb-5">
          {title}
        </h1>
        <div className={`w-3/5 mx-auto flex mt-${marginTop}`}>
          <img
            src={imgSrc}
            alt="Area Of Analysis"
            className="object-cover rounded-lg"
          />
        </div>
        <div className={`mt-${marginTop}`}>
          <button
            className="bg-blue-500 text-white flex mx-auto items-center px-4 py-2 rounded-md cursor-pointer"
            onClick={() => handleGenerateAnalysis(areaOfAnalysisCode)}
          >
            <FaLightbulb className="mr-2" />
            <span>Generate Insights</span>
          </button>
          {areaOfAnalysisCode === "INSURANCE" && (
            <button
              className="bg-white-500 text-blue-500 flex mx-auto items-center px-4 py-2 mt-2 rounded-md cursor-pointer"
              onClick={navigateToGenAIProComparer}
            >
              <FaExchangeAlt className="mr-2" />
              <span>Try Compare</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
