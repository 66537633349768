
import React, { useEffect, useState } from "react";
import "../output.css";

const SkeletonLoader = () => {
  const [numCols, setNumCols] = useState(1);

  useEffect(() => {
    const parentHeight = document.querySelector('.size-full').clientHeight;
    const rowHeight = 30; 
    const calculatedCols = Math.floor(parentHeight / rowHeight);
    setNumCols(calculatedCols > 0 ? calculatedCols : 1);
  }, []);

  const skeletonRows = [];
  for (let i = 0; i < numCols; i++) {
    skeletonRows.push(
      <div key={i} className="h-2 bg-slate-200 rounded"></div>
    );
  }

  return (
    <div className="overflow-y-auto border border-blue-300 shadow rounded-md p-4 w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="space-y-3">
            <div className="grid grid-cols-1 gap-4">
              {skeletonRows}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
