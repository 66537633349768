import React from "react";
import TypingAnimation from "../TypingAnimation";
import SkeletonLoader from "../SkeletonLoader";
import { FaBrain } from "react-icons/fa";
const DeepAnalysisPreview = ({ conversationFlowAnalysis }) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="w-1/2 h-full p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-mono font-bold mb-4">
          <FaBrain className="inline-block mr-1 animate-pulse" /> Deep Analysis
          and Recommendations:
        </h1>
        {conversationFlowAnalysis ? (
          <div
            style={{ maxHeight: "38rem" }}
            className="overflow-y-auto max-h-128 mb-20 p-4 w-full mx-auto"
          >
            <TypingAnimation text={conversationFlowAnalysis} />
          </div>
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  );
};
export default DeepAnalysisPreview;
