import React, { useState, useEffect } from "react";
import TypingAnimation from "../TypingAnimation";
import SkeletonLoader from "../SkeletonLoader";
import { FaListUl } from "react-icons/fa";
import { API_ENDPOINTS } from "../../constants/apiEndpoint";
import { axiosGet } from "../../utils/axiosUtils";
import { formatData } from "../../utils/formatData";
const SummaryPreview = ({ transcriptionSummary }) => {
  const [selectedTermAdditionalInfo, setSelectedTermAdditionalInfo] =
    useState("");
  const [openIndex, setOpenIndex] = useState(null);
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchAdditionalInfo = async (keyword) => {
      const DOMAIN = "PHARMA";
      if (keyword !== null) {
        try {
          const ADDITIONAL_INFO_API_URL =
           
           
            API_ENDPOINTS.ADDITIONAL_INFO_API_PATH +
            "?keyword=" +
            keyword +
            "&domain=" +
            DOMAIN;
          const response = await axiosGet(ADDITIONAL_INFO_API_URL);
          const { data } = response.data;
          let result = formatData(data);
          result = result.trim();
          setSelectedTermAdditionalInfo(result);
        } catch (error) {
          setSelectedTermAdditionalInfo("");
        }
      }
    };
    if (openIndex)
      fetchAdditionalInfo(
        transcriptionSummary[0]?.diseases_and_conditions[openIndex]
      );
    return () => {
      setSelectedTermAdditionalInfo("");
    };
  }, [openIndex]);
  return (
    <div className="flex-1 flex flex-col">
      <div className="w-full p-8 rounded-lg shadow-lg">
        <div className="mb-4">
          <h1 className="text-2xl font-mono font-bold mb-4">
            <FaListUl className="inline-block mr-1 animate-pulse" /> Summary
          </h1>
          {transcriptionSummary ? (
            Array.isArray(transcriptionSummary) === true ? (
              <div className="space-y-8">
                {transcriptionSummary.map((item, index) => (
                  <div key={index} className="p-4">
                    <TypingAnimation
                      text={item?.summary || "Summary Not available"}
                    />
                    <h1 className="text-xl font-bold mt-5">Keywords:</h1>
                    <div className="mt-5">
                      <div>
                        {item?.drug_names?.brand_names &&
                          item?.drug_names?.brand_names.length > 0 && (
                            <>
                              <h3 className="text-lg font-semibold mb-2">
                                Brand Names:
                              </h3>
                              <ol className="list-decimal pl-6 mb-4">
                                {item?.drug_names?.brand_names?.map(
                                  (brand, i) => (
                                    <li key={i}>{brand}</li>
                                  )
                                )}
                              </ol>
                            </>
                          )}
                        {item?.drug_names?.generic_names &&
                          item?.drug_names?.generic_names.length > 0 && (
                            <>
                              <h3 className="text-lg font-semibold mb-2">
                                Generic Names:
                              </h3>
                              <ol className="list-decimal pl-6 mb-4">
                                {item?.drug_names?.generic_names?.map(
                                  (generic, i) => (
                                    <li key={i}>{generic}</li>
                                  )
                                )}
                              </ol>
                            </>
                          )}
                      </div>
                    </div>
                    <div></div>
                    <div>
                      <h2 className="text-xl font-semibold mb-2">
                        Diseases and Conditions:
                      </h2>
                      <ol className="list-disc pl-6 mb-4">
                        {item?.diseases_and_conditions?.map((condition, i) => (
                          <li key={i}>
                            <button
                              onClick={() => toggleAccordion(i)}
                              className="flex justify-between w-full bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-md mb-2"
                            >
                              <span>{condition}</span>
                              <span>{openIndex === i ? "-" : "+"}</span>
                            </button>
                            {openIndex === i && (
                              <div className="p-4 bg-gray-100 rounded-md">
                                {selectedTermAdditionalInfo ? (
                                  <div
                                    style={{ maxHeight: "10rem" }}
                                    className="overflow-y-auto max-h-128 w-full mx-auto resize"
                                  >
                                    <TypingAnimation
                                      text={selectedTermAdditionalInfo}
                                    />
                                  </div>
                                ) : (
                                  <p className="animate-pulse text-gray-400">
                                    Fetching additional information about{" "}
                                    {condition}.....
                                  </p>
                                )}
                              </div>
                            )}
                          </li>
                        ))}
                      </ol>
                    </div>
                    <div className="mt-5">
                      <div>
                        {item?.manufacturers &&
                          item?.manufacturers.length > 0 && (
                            <>
                              <h3 className="text-lg font-semibold mb-2">
                                Manufacturers:
                              </h3>
                              <ol className="list-decimal pl-6 mb-4">
                                {item?.manufacturers?.map((manufacturer, i) => (
                                  <li key={i}>{manufacturer}</li>
                                ))}
                              </ol>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div
                  style={{ maxHeight: "40rem" }}
                  className="overflow-y-auto mb-5 p-4 w-full mx-auto"
                >
                  <TypingAnimation text={transcriptionSummary} />
                </div>
              </div>
            )
          ) : (
            <SkeletonLoader />
          )}
        </div>
      </div>
    </div>
  );
};
export default SummaryPreview;
