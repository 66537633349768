import React, { Suspense } from "react";
import "./output.css";
import RouteConfig from "./routes/RouteConfig";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <RouteConfig />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
